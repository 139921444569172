import React, { useState } from 'react';
import './Stylesheets/Dropdown.css'

const ClassDropdown = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className='DropdownContainer'>
      <label htmlFor="class-dropdown">Choose a class:</label>
      <select 
      className='DropdownMenu'
        id="class-dropdown"
        value={selectedOption}
        onChange={handleChange}
      >
        <option value="" disabled>Select an option</option>
        <option value="adult">Adult Class</option>
        <option value="kids">Kids Class</option>
      </select>
    </div>
  );
};

export default ClassDropdown;
