import './Stylesheets/FamilyDiscountBadge.css'
import { useState, useEffect, useRef } from 'react'

export default function FamilyDiscountBadge(){
  const itemRef = useRef(null);
  const [badgeVisible, setbadgeVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
            const entry = entries[0]; // Since we are observing only one item
            if (entry.isIntersecting) {
              setbadgeVisible(true);
                observer.unobserve(entry.target); // Unobserve after it becomes visible
            }
        },
        { threshold: 0.2 }
    );

    if (itemRef.current) {
        observer.observe(itemRef.current); // Observe the single item
    }

    return () => {
        if (itemRef.current) {
            observer.disconnect(); // Cleanup observer
        }
    };
}, [itemRef]);
    return (
        <div ref={itemRef} className={`family-discount-badge ${badgeVisible? 'large':''}`}>
          <span className="family-discount-text">Each immediate family member receives a 25% discount!</span>
        </div>
      );

}