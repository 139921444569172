import './Stylesheets/FreeClass.css'
import { useState, useEffect, useRef } from 'react'
import { InlineWidget } from 'react-calendly';
import { ApplicationContext } from './Context';
import ClassDropdown from './ClassDropdown';
import GetStartedButton from './GetStartedButton';

export default function FreeClass(){
    const [classType, setClassType] = useState(false)
    const itemRef = useRef(null);
    const { handleClick, getStarted } = ApplicationContext();
     
    const [whyWaitVisible, setWhyWaitVisible] = useState(false);
    const handleSelect = (selectedClass) => {
      setClassType(selectedClass);
    };

  


    const renderClassWidget = () => {
      switch (classType) {
        case 'adult':
          return (
            <div className='AdultTrial'>
              <InlineWidget
                url="https://calendly.com/jeff000hughes/introductory-class"
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: '00a2ff',
                  textColor: '4d5055',
                }}
                styles={{
                  height: '1000px',
                }}
              />
            </div>
          );
        case 'kids':
          return (
            <div className='KidsTrial'>
              <InlineWidget
                url="https://calendly.com/jeff000hughes/introductory-kids-class"
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: '00a2ff',
                  textColor: '4d5055',
                }}
                styles={{
                  height: '1000px', width:'100vw'
                }}
              />
            </div>
          );
        default:
          return null;
      }
    };

    useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              const entry = entries[0]; // Since we are observing only one item
              if (entry.isIntersecting) {
                setWhyWaitVisible(true);
                  observer.unobserve(entry.target); // Unobserve after it becomes visible
              }
          },
          { threshold: 0.2 }
      );
  
      if (itemRef.current) {
          observer.observe(itemRef.current); // Observe the single item
      }
  
      return () => {
          if (itemRef.current) {
              observer.disconnect(); // Cleanup observer
          }
      };
  }, [itemRef]);
  
 

    return(<>
     
        <div className='FreeClassContainer' id='FreeClass'>
        <div ref={itemRef} >
       <h1>
 
        <div className={`WhyWait ${whyWaitVisible? 'show':''}`}>Why wait?</div><br></br><div className={`FirstStep ${whyWaitVisible? 'show':''}`}>Take the first step. <span className='Crimson'>Book your free class right now!</span></div></h1>
        </div>

        
  
            <div className={`FreeClassButtons ${getStarted ? 'white':null}`}>
           { getStarted ?<ClassDropdown onSelect={handleSelect}  />:
            <GetStartedButton handleClick={handleClick} /> 
            }
            {renderClassWidget()}
           
            </div>

          
    
            {classType? null:
            <div className='JockoContainer'>
                   <iframe className='Jocko'
                  
                   src="https://www.youtube.com/embed/EGNEc2Dppgc?si=FZa_87A2H0yQYu-M?"
                   title="YouTube video player"
                  
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                   referrerPolicy="strict-origin-when-cross-origin"
                   allowFullScreen
                 ></iframe>
                 </div>
           
         }

        </div>


        </> )
}
 