import './Stylesheets/Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import instagram from './Images/instagram-logo-instagram-icon-transparent-free-png.png'
import fb from './Images/pngimg.com - facebook_logos_PNG19757.png'
import img from './Images/background.jpg'


export default function Contact() {

    return(
        <div className='ContactContainer' id='Contact'>
          <h1>Call us, email us, drop by</h1>
           
             <div className='LocationFlex'>
  
       
        <div className='ContactInformation'>
        <div className='Phone'>
        <FontAwesomeIcon icon={faPhoneAlt} className="phone-icon" /> 
        <p>(416)-970-8068</p>
        </div>
        <div className='Email'>
        <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
        <p>chesswoodbjj@gmail.com</p>
        </div>
           
                <div className='Address'>
        <FontAwesomeIcon icon={faHome} className="address-icon" />
        <p>4000 Chesswood Dr, North York, ON, M3J 2B9</p>
        </div>

        <div className='SocialMedia'>
      <h1>Folow us on social media</h1>
        <div className='SocialMediaIcons'>
        <a
                href="https://www.instagram.com/chesswoodbjj/?hl=en"
                target="_blank"
               
            >
               
            
        <img src={instagram}/></a>
        <a
                href="https://www.facebook.com/chesswoodbjj?paipv=0&eav=AfYVylMIoHqs8a_Y0nHxEUmoHKB3kvo7HzSYMLrE05q31C5Vno45avuj1fmboGRJzRI&_rdr"
                target="_blank"
               
            >
        <img src={fb}/></a>
        </div>


        </div>
        <div className='Directions'>
        <h3>Located at the top level of Chesswood Arena</h3>
        <p>Enter through the doors to the left of the main entrance and take the stairs to the top floor. Walk until the end of the hallway, make a left and you will have arrived at Chesswood BJJ!</p>
        </div>
         
        </div>
        
      <div className='MapContainer'>
           
                    <iframe
                    className='Map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.7841637692486!2d-79.48017042336572!3d43.75657864573783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b319db5c986a9%3A0x2e8c805b2e78b4d7!2sChesswood%20Brazilian%20Jiu-Jitsu!5e0!3m2!1sen!2sca!4v1720996860552!5m2!1sen!2sca"
         
          style={{ border: '1px solid black', borderRadius:'5px', margin:'0 auto'}}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe> 
        
       
        </div>

       
    
        </div>
    
        
       </div>
        
    )

}