import './Stylesheets/HorizontalNavbar.css';
import logo from './Images/tyndallv-2d_cropped.png';
import schedule from './Images/schedule2.png'
import instructors from './Images/Instructors2.png'
import pricing from './Images/Pricing2.png'
import contact from './Images/contact2.png'
import testimonials from './Images/testimonials.png'
import freeclass from './Images/FreeClass.png'
import GetStartedButton from './GetStartedButton';
import { ApplicationContext } from './Context';
import { useState, useEffect } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';
 

export default function HorizontalNavbar() {
    const [click, setClick] = useState(false);
    const HandleLocalClick = () => setClick(!click);
    const { handleClick, getStarted } = ApplicationContext();
    // Change nav color when scrolling
    const [color, setColor] = useState(false);

    useEffect(() => {
        if (click) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
    
        // Clean up function to reset overflow when component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [click]);
    const changeColor = () => {
        if (window.scrollY >= 400) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);

    const closeMenu = () => setClick(false);

    return (
        
            <nav className={`horizontalnavbar ${color ? 'header header-bg' : 'header'}`}>
                <div className='LogoContainer'>
                         <img id='logo' className='logo' alt="Chesswood BJJ" src={logo} />
                 </div>
                <div className='hamburger' onClick={HandleLocalClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}
                </div>
     
                <ul className={click ? "nav-menu active" : "nav-menu"}>

                <div className='FreeClassButton'>
                <a href='#FreeClass' onClick={() => { closeMenu(); HandleLocalClick(); }}>
            <GetStartedButton handleClick={handleClick} />
            </a>
            </div>
            <div className='NavItems'>
                    <li className='nav-item'>
                        <a href='#Schedule' spy={true} smooth={true} duration={500} onClick={closeMenu}>
                        <img  alt="schedule" src={schedule} />
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#Instructors' spy={true} smooth={true} offset={-80} duration={500} onClick={closeMenu}>
                        <img  alt="Instructors" src={instructors} />
                        </a>
                    </li>
                  
                    <li className='nav-item'>
                        <a href='#Pricing' spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>
                        <img  alt="pricing" src={pricing} />
                        </a>
                    </li>
               
                    <li className='nav-item'>
                        <a href='#Testimonials' spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>
                        <img  alt="testimonials" src={testimonials} />
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#Contact' spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>
                        <img  alt="contact" src={contact} />
                        </a>
                    </li>
                    </div>
                </ul>
               
            </nav>
       
    );
}
