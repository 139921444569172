import schedule from './Images/chesswood-bjj-schedule (1).webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';  // Import the calendar icon
import './Stylesheets/ScheduleImage.css'

export default function ScheduleImage(){
    return(
        <div className='ScheduleImageContainer' id='Schedule'>
               <div className='ScheduleTitle'>
    
    <h1> Schedule</h1>
    </div>
        <img src={schedule}/>

        <div className='ScheduleText'>

            <div className='DayOfTheWeek'>
                <h3>Monday                </h3>
                <div className='ClassNameAndTime'>
                <h4>Bjj Class</h4>
                <p>7:00-8:30pm</p>
                </div>

            </div>

            <div className='DayOfTheWeek'>
            <h3>Tuesday                </h3>
            <div className='ClassNameAndTime'>
                <h4>Pro Drilling</h4>
                <p>11:00am-1:00pm</p>
                </div>
                <div className='ClassNameAndTime'>
                <h4>Kids BJJ Class</h4>
                <p>5:30-6:30pm</p>
                </div>
                <div className='ClassNameAndTime'>
                <h4>Bjj Class</h4>
                <p>7:00-8:30pm</p>
                </div>
            </div>

            <div className='DayOfTheWeek'>
            <h3>Thursday                </h3>
            <div className='ClassNameAndTime'>
                <h4>Pro Drilling</h4>
                <p>11:00am-1:00pm</p>
                </div>
                <div className='ClassNameAndTime'>
                <h4>Kids BJJ Class</h4>
                <p>5:30-6:30pm</p>
                </div>
                <div className='ClassNameAndTime'>
                <h4>Bjj Class</h4>
                <p>7:00-8:30pm</p>
                </div>
            </div>
                
          

            <div className='DayOfTheWeek'>
            <h3>Saturday                </h3>
            <div className='ClassNameAndTime'>
                <h4>No-Gi Class</h4>
                <p>1:00-2:00pm</p>
                </div>
                
                </div>

            <div className='Sunday DayOfTheWeek'>
            <h3>Sunday                </h3>
            <div className='ClassNameAndTime'>
                <h4> Open Mat (Members Only)</h4>
                <p>1:00-2:00pm</p>
            </div>
                
                </div>

                </div>
        </div>
    



       
    )
}