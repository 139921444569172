import './Stylesheets/Pricing.css'
import { useState, useEffect, useRef } from 'react'

 
import FamilyDiscountBadge from './FamilyDiscountBadge'
import logo from './Images/tyndallv-2d_cropped.png'
import Quote from './Quote'
 
export default function() {
 
    

    return(
 <div className='PriceOuterContainer' id='Pricing'>
  

<div className='PriceContainer'>

          <div className='PriceTable'>
            <div className='Adult'>
                <h3>Adult Memberships</h3>
                <div className='Year'>
                <div className='FirstPart'>
                    <h4 className='heading'>Annual </h4>  
                    <div className='Upfront'>
                        <h4> Option 1: Single Upfront Payment of $990</h4>               
                    </div>
                    <div className='Monthly'>
                        <h4 > Option 2: Monthly Payments of $99 via Pre-Authorized Debit </h4>
                    </div>
                    </div>
                    <div className='SecondPart'>
                    <h4 className='heading'>Monthly </h4>
                    <h4> Monthly Payments of $130 via Pre-Authorized Debit</h4>
                    </div>
                </div>         
            </div>         
            <div className='Kid'>
                <h3>Kids Memberships</h3>
                <div className='Year'>
                <div className='FirstPart'>
                    <h4 className='heading'>Annual</h4>
                    <div className='Upfront'>                   
                        <h4> Option 1: Single Upfront Payment of $750</h4>
                    </div>
                    <div className='Monthly'>
                        <h4 > Option 2: Monthly Payments of $79 via Pre-Authorized Debit </h4>
                    </div>
                    </div>
                    <div className='SecondPart'>
                    <h4 className='heading'>Monthly </h4>
                    <h4> Monthly Payments of $100 via Pre-Authorized Debit</h4>
                    </div>    
                    </div> 
                </div>
                <div className='Kid Private'>
                <h3>Private Lessons</h3>
 
                <div className='FirstPart'>
                    <div className='Upfront'>                   
                        <h4> $120 per hour </h4>
                        <h4>Maximum group size: 4 people</h4>
                    </div>
                </div>
                    
                      
                </div>
        </div>
        <div className= 'SecondColumn'>
        <div className='disclaimer'>
        *All prices subject to HST. All sales are final. <br></br>
        <strong>Memberships must be cancelled 30 days in advance of next payment.
        </strong>

        </div>
        
        <FamilyDiscountBadge />
  
        </div>
     
        </div>
        </div>
         
    )
}