import React from 'react';
import './Stylesheets/GetStartedButton.css';

const GetStartedButton = ({handleClick}) => {
    return (
        <button onClick={handleClick} className="get-started-button">
            BOOK MY FREE CLASS
        </button>
    );
};

export default GetStartedButton;
