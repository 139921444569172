import './Stylesheets/Instructors.css';
import Nick from './Images/Nick.jpg'
import Andrew from './Images/andrew.jpg'
import Jesse from './Images/jesse.jpg'
import Matt from './Images/mp.jpg'
import { useState, useEffect, useRef } from 'react';

const BlackBelt = () => (
    <div className='Belt'>
        {[...Array(5)].map((_, i) => <div key={i} className='black'></div>)}
        {[...Array(2)].map((_, i) => <div key={i + 5} className='red'></div>)}
        {[...Array(2)].map((_, i) => <div key={i + 7} className='black'></div>)}
    </div>
);

const BrownBelt = () => (
    <div className='Belt'>
        {[...Array(5)].map((_, i) => <div key={i} className='brown'></div>)}
        {[...Array(2)].map((_, i) => <div key={i + 5} className='black'></div>)}
        {[...Array(2)].map((_, i) => <div key={i + 7} className='brown'></div>)}
    </div>
);

export default function Instructors() {
    const instructors = [
        { name: 'Andrew Mccoll',  img:Andrew, belt: 'BlackBelt' , bio: ['BJJ Black Belt under Jorge Britto','12 years of experience in Brazillian Jiujitsu', 'Member of the York Lions varsity wrestling team', 'Multiple time IBJJF Open gold medalist', 'Referee for world class tournament organizations, such as the OJA, OGA, Abu Dhabi Pro, and Grappling Industries'] },
        { name: 'Matthew Pasquale',  img:Matt, belt: 'BlackBelt' , bio: ['BJJ Black Belt under Jorge Britto','14 years of experience in Brazillian Jiujitsu','Strength and Conditioning Specialist', 'IBJJF Pan Am and World Champtionship medallist'] },
        { name: 'Satoshi Kondo', img:Nick, belt: 'BrownBelt' , bio: ['BJJ Brown Belt under Andrew Mccoll','6 years of experience in Brazillian Jiujitsu', '2023 IBJJF Toronto Open Middleweight and Openweight Champion', ' Ranked Top 5 in the IBJJF Purple Belt Middle weight division', 'Gold Medalist at the IBJJF Chicago Open, Santa Cruz Open, Nashville Open, Indianapolis Open, and New Haven Open']},
        { name: 'Jesse Hebscher', img:Jesse, belt: 'BrownBelt' , bio: ['BJJ Brown Belt under Andrew Mccoll', '8 years of experience in Brazillian Jiujitsu', '2022 Ontario Open Champion', 'Former OHL Hockey Player']}
    ];
    const itemRefs = useRef(new Array(instructors.length).fill(null));
    const [isVisible, setIsVisible] = useState(new Array(instructors.length).fill(false));

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting) {
                        // Find the index of the item using the ref array
                        const itemIndex = itemRefs.current.findIndex(ref => ref === entry.target);
                        // Update visibility state for the item
                        setIsVisible(prev => prev.map((visible, idx) => idx === itemIndex ? true : visible));
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe each service item
        itemRefs.current.forEach((ref, index) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [itemRefs]);

    return (
        <div className='InstructorContainer' id='Instructors'>
            <div className='InstructorText'>
    
            <h2> Learn from some of <span className='Crimson'>Canada's</span> most accomplished BJJ black and brown belts.</h2>
            <p>Over 40 years of combined Brazillian Jiujitsu experience between our instructors who regularly compete at the highest levels of the sport. Come train and learn from the best!</p>
            </div>
            <div className='Instructors'>
                
            {instructors.map((item, index) => (
                <div ref={el => itemRefs.current[index] = el}  key={index} className='Instructor'>
                    <div className={`PictureAndBelt ${isVisible[index]? 'isVisible': ''}`}>
                    <img className={`${item.belt === 'black' ? 'blackframe' : 'brownframe'}`} src={item.img} />
                  
                    </div>
                    <div className={`Description ${isVisible[index]? 'isVisible': ''}`}>
                     <div className='InstructorName'>   {item.name} </div>
                     {item.belt === 'BlackBelt' ? <BlackBelt /> : <BrownBelt />}

                        <ul>
                        {item.bio.map((x, index)=>(
                            <li>
                                {x}
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
}
