// ImageComponent.js
import React from 'react';
import './Stylesheets/ImageComponent.css'; // Adjust the path as needed
import testimonialsPic from './Images/TestimonialsPicture.png';
function ImageComponent() {
    return (
        <div className="image-container">
            <img src={testimonialsPic} alt="Centered" />
        </div>
    );
}

export default ImageComponent;
