import {useState, useEffect} from 'react';
import './Stylesheets/Navbar.css';
import schedule from './Images/schedule2.png'
import instructors from './Images/Instructors2.png'
import pricing from './Images/Pricing2.png'
import contact from './Images/contact2.png'
import testimonials from './Images/testimonials.png'
import freeclass from './Images/FreeClass.png'
import logo from './Images/img_6355_cropped-1.png'
import GetStartedButton from './GetStartedButton';
import { ApplicationContext } from './Context';
import { FaTimes, FaBars } from 'react-icons/fa';
import fulllogo from './Images/tyndallv-2d_cropped.png'

 export default function Navbar(){
  const { handleClick, getStarted } = ApplicationContext();
  const [click, setClick] = useState(false);
  const HandleLocalClick = () => setClick(!click);
  const closeMenu = () => setClick(false);
  useEffect(() => {
    if (click) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Clean up function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [click]);
 
    return(
        <div className='Navbar'>

               <nav className='horizontalnavbar heade'>
                <div className='LogoContainer'>
                         <img id='secondlogo' className='logo' alt="Chesswood BJJ" src={fulllogo} />
                 </div>
                <div className='hamburger' onClick={HandleLocalClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}
                </div>
     
                <ul className={click ? "nav-menu active" : "nav-menu"}>

 
            <div className='NavItems'>
                    <li className='nav-item'>
                        <a href='#Schedule'    onClick={closeMenu}>
                        Schedule
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#Instructors' onClick={closeMenu} >
                        Instructors
                        </a>
                    </li>
                  
                    <li className='nav-item'>
                        <a href='#Pricing'  onClick={closeMenu} >
                        Pricing
                        </a>
                    </li>
               
                    <li className='nav-item'>
                        <a href='#Testimonials'  onClick={closeMenu} >
                        Testimonials
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#Contact'onClick={closeMenu} >
                        Contact Us
                        </a>
                    </li>
                    </div>
                </ul>
               
            </nav>
              <img id='CenterLogo'  src={logo} alt='Chesswood' /> 
    <a href='#FreeClass'>  <GetStartedButton handleClick={handleClick}/> </a>
     
 
        </div>
    )
 }