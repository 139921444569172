import './Stylesheets/VideoComponent.css'
import { useState, useEffect } from 'react';
import video from './Videos/herovideo.mp4'
import free from './Images/free.png'
import cls from './Images/class.png'

export default function VideoComponent(){
    const [visible, setVisible] = useState(false);

    const rows = 100; // Number of rows
    const cols = 2 // Number of columns per row


    useEffect(() => {
        setVisible(false);
        // Use setTimeout to ensure the reset happens after the DOM updates
        setTimeout(() => {
          setVisible(true);
        }, 100);
      }, []);

    
      const divs = Array.from({ length: rows }, (_, rowIndex) => (
        <div key={rowIndex} className="div-row">
          {Array.from({ length: cols }, (_, colIndex) => (
            <div key={colIndex} className={`my-div ${(rowIndex + colIndex) % 2 === 0 ? 'white' : 'black'}`}></div>
          ))}
        </div>
      ));

    return(
      <div className="hero-outer-container">
        <div className="hero-container" id="hero">
        
        <video autoPlay loop muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        
        <div className="video-overlay"></div> {/* Add overlay div here */}
        <div className={`ForefrontText ${visible? 'visible': ''}`}>
 
    
        </div>
    </div>
    </div>
    )
}