import './Stylesheets/Footer.css'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import logo from './Images/tyndallv-2d_cropped.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';

export default function() {
    
    
    return(

            <footer className='Footer'>
         <div>  Created by <span className='K'> 5K Studios LTD.</span> </div>   <div>  All Rights Reserved</div>
            </footer>

 
    )
}