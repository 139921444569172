// ParallaxComponent.js
import {useState, useEffect} from 'react';
import { Parallax } from 'react-parallax';
import './Stylesheets/ParallaxComponent.css';
import backgroundImage1 from './Images/skkids.png'; // Adjust the path
import backgroundImage2 from './Images/kids.png'
function ParallaxComponent() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
  const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const backgroundImage = windowWidth < 800 ? backgroundImage1 : backgroundImage2;
    return (
        <div className='ParallaxContainer'>
            <Parallax  bgImageStyle={{   top:"25%",  width:'100vw', height: 'auto',}} bgImage={backgroundImage} strength={350}>
                <div style={{ height: '100vh' }}>
                    <div className="content">
                     
                    </div>
                </div>
            </Parallax>
         
        </div>
    );
}

export default ParallaxComponent;
