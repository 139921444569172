import { Parallax } from "react-parallax";
import CheckeredLine from "./CheckeredLine";
import Contact from "./Contact";
import Footer from "./Footer";
import FreeClass from "./FreeClass";
import HorizontalNavbar from "./HorizontalNavbar";
import Instructors from "./Instructors";
import Navbar from "./Navbar";
import ParallaxComponent from "./ParallaxComponent";
import Pricing from "./Pricing";
import { AppContextProvider, ApplicationContext } from './Context';
import Schedule from "./Schedule";
import ScheduleImage from "./ScheduleImage";
import './Stylesheets/App.css'
import Testimonials from "./Testimonials";
 
import VideoComponent from "./VideoComponent";
import Parallax2 from "./Parallax2";

function App() {
  return (
    <AppContextProvider >
    <div className="App">
  
      <Navbar />
     <HorizontalNavbar />
      <VideoComponent />
     
      <ScheduleImage />
      
         <Parallax2 />
      <Instructors />
     <CheckeredLine />
 
      <Pricing /> 
      <ParallaxComponent />
     
      <FreeClass />
      <CheckeredLine />
      <Testimonials />
      <CheckeredLine />  
      <Contact />
      <CheckeredLine />  
      <Footer />
 
    
   
    </div>
    </AppContextProvider>
  );
}

export default App;

