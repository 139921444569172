import React, { useState, useEffect, useRef } from 'react';
import './Stylesheets/Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import testimonialsPic from './Images/TestimonialsPicture.png';
import StarRating from './Stars';
import ImageComponent from './ImageComponent';

export default function Testimonials() {
    const [reviewIndex, setReviewIndex] = useState(0);
    const [nextReviewIndex, setNextReviewIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [slideDirection, setSlideDirection] = useState(''); // 'left' or 'right'
    const itemRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const reviews = [
        { text: "My favourite place to train Brazilian Jiujitsu. The classes are very enjoyable and it is a great friendly atmosphere.", author: "Jeff Hughes" },
        { text: "Fully recommend Chesswood BJJ. Both myself and my son have started classes here and love it! Nick is an amazing instructor - he is great with the kids and adults. Looking forward to being here for a long time.", author: "Sheyan Jayatunga" },
        { text: "Best Bjj gym! Coach Nick and his team has made and extremely professional and friendly environment with the most modern and precise techniques. Started Bjj at chesswood in July and barely missed any classes since then!", author: "Faryan Nasiri" },
        { text: "Very welcoming gym for beginners, all members are extremely hospitable and help you learn the beautiful sport of BJJ. I highly recommend Chesswood BJJ for all.", author: "Ausaf Kahlon" },
        // ... more reviews
    ];
   
    useEffect(() => {
        if (isVisible) {
            const timeoutId = setTimeout(() => {
                setIsVisible(false);
            }, 1000);
    
            // Clear the timeout if the component unmounts or `isVisible` changes
            return () => clearTimeout(timeoutId);
        }
    }, [isVisible]);
    


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0]; // Since we are observing only one item
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target); // Unobserve after it becomes visible
                }
            },
            { threshold: 0.2 }
        );

        if (itemRef.current) {
            observer.observe(itemRef.current); // Observe the single item
        }

        return () => {
            if (itemRef.current) {
                observer.disconnect(); // Cleanup observer
            }
        };
    }, [itemRef]);

    const moveRight = () => {
        if (!isSliding) {
            setNextReviewIndex((reviewIndex + 1) % reviews.length);
            setSlideDirection('right');
            setIsSliding(true);
        }
    };

    const moveLeft = () => {
        if (!isSliding) {
            setNextReviewIndex((reviewIndex - 1 + reviews.length) % reviews.length);
            setSlideDirection('left');
            setIsSliding(true);
        }
    };

    const handleTransitionEnd = () => {
        console.log('fuckyou')
        setReviewIndex(nextReviewIndex);
        setSlideDirection('');
        setIsSliding(false);
    };

    useEffect(()=>{
    setTimeout(() => {
        if (slideDirection ){setSlideDirection('e')}
    }, 100);  


    },[slideDirection])

    return (
        <div className="ReviewsOuterContainer" id='Testimonials'>
            <div className='ReviewTitle'>
            <h1 ref={itemRef}><FontAwesomeIcon  className= {`comments ${isVisible? 'enlarge':''}`} icon={faComments} /></h1>
            <h2>Hear What Others Have to Say..</h2>
            </div>

            <div className="ReviewsContainer">
                <div className="ButtonContainer">
                    <button onClick={moveLeft}>&lt;</button>
                </div>
                <div className="ReviewWrapper">

                   {  slideDirection? null: <div
                        className={`Review-Text ${slideDirection ? 'slide-out-' + slideDirection : ''}`}
                       
                    >
                        <p className="txt">{reviews[reviewIndex].text}</p>
                        <div className="Rating">
                            <StarRating />
                        </div>
                        <p className="ReviewAuthor">{reviews[reviewIndex].author}</p>
                    </div>}
               { slideDirection?   <div  onTransitionEnd={handleTransitionEnd}
                        className={`Review-Text next ${'slide-in-from-' + slideDirection}`}
                    >
                        <p className="txt">{reviews[nextReviewIndex].text}</p>
                        <div className="Rating">
                            <StarRating />
                        </div>
                        <p className="ReviewAuthor">{reviews[nextReviewIndex].author}</p>
                    </div> : null}
                </div> 
                <div className="ButtonContainer">
                    <button onClick={moveRight}>&gt;</button>
                </div>
            </div>
                   <ImageComponent />
            
        </div>
    );
}
