// ParallaxComponent.js
import {useState, useEffect} from 'react';
import { Parallax } from 'react-parallax';
import './Stylesheets/Parallax2.css';
import backgroundImage1 from './Images/skinny.png'; // Adjust the path
import backgroundImage2 from './Images/prlx.png'
function Parallax2() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
  const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const backgroundImage = windowWidth < 800 ? backgroundImage1 : backgroundImage2;
    return (
        <div className='ParallaxContainer'>
            <Parallax bgImageStyle={{   top:"20%",  height: 'auto',width:'100vw'}} bgImage={backgroundImage} strength={400}>
                <div style={{ height: '100vh' }}>
                    <div className="content">
                     
                    </div>
                </div>
            </Parallax>
         
        </div>
    );
}

export default Parallax2;
